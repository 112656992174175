/* Базовые стили */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(to right, #28203b, #3d2e53);
  color: #ffffff;
  height: 100%;
}
html{height:100%}

#root {
  height: 100%;
}

.headerInfoMini{
  position: fixed;
  top:0;
  left: 0;
  height:30px;
  background: #ffffff;
  width:100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Радио */
.radio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.radio-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.station-info {
  margin-bottom: 10px;
}

.station-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.current-track {
  font-size: 1.2rem;
  margin: 10px 0;
}

.listeners {
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Плеер */
.player {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #4c3e5f;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
}

.rhap_container {
  padding: 10px;
}

.rhap_progress-section{display: none !important;}

.rhap_main-controls button[aria-label="Play"] svg path,
.rhap_main-controls button[aria-label="Pause"] svg path {
  fill: #28203b;
}

.rhap_volume-controls {
  justify-content: center;
}

/* Мобильные стили */
@media (max-width: 768px) {
  .station-name {
    font-size: 1.2rem;
  }

  .current-track {
    font-size: 1rem;
  }
}
